.navbar {
  background-color: var(--primary-color) !important;
  border-radius: 16px;
  margin: var(--margin) auto;
  width: 95%;
}
.navbar .dropdown button {
  background-color: transparent;
  border: 0;
}
.navbar .dropdown button:focus {
  background-color: transparent;
  border: 0;
}
.navbar .navbar-nav .nav-link {
  color: var(--white-color) !important;
  font-size: 16px;
}
.navbar .navbar-nav .active {
  font-weight: 700;
}
.navbar .navbar-collapse .btn-contact {
  background-color: var(--white-color);
  color: var(--primary-color);
  font-size: 16px;
  font-weight: 600;
  padding: 8px 16px;
  text-decoration: none;
  border-radius: 5px;
  display: block;
}
.navbar .navbar-collapse .change_lng {
  background-color: var(--primary-color);
  color: #fff;
  font-weight: 700;
  border: 0;
  border-bottom: 1px solid #fff;
}
.navbar-toggler span {
  filter: invert(1) !important;
}
