.team {
  padding: 70px 0px;
}
.team a {
  display: block;
  text-decoration: none;
}
.team a h3 {
  color: var(--primary-color);
  font-size: 40px;
  font-weight: 800;
  margin-bottom: 40px;
}
.team a h5 {
  font-size: 25px;
  font-weight: 700;
  color: black;
}
.team a h6 {
  font-size: 14px;
  font-weight: 600;
  color: black;
}
.team p {
  font-size: 16px;
  color: #616161;
  margin-top: 20px;
}
.custom-navigation {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  gap: 20px;
}

.custom-arrow {
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin: 0 10px;
  font-size: 24px;
}

@media screen and (max-width: 763px) {
  .team img {
    width: 100%;
  }
}

.small-image {
  max-width: 100%;
  height: auto;
  width: 300px;
}
