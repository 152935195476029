.home section{
    margin: 50px auto;
}
.home section .col-md-4 > div{
    text-align: center;
    background-color: var(--primary-color);
    color: var(--white-color);
    padding: 12px 10px;
    border-radius: 15px;
    margin-bottom: 20px;
}
.home section .col-md-4 > div img{
    height: 90px;
}
.home section .col-md-4 > div h4{
    font-size: 35px;
    font-weight: 700;
}
.home .second-section{
    background-color: var(--primary-color);
    color: var(--white-color);
    background-image: url(../../assets/img/image-4.png);
    background-repeat: no-repeat;
    background-position: right center;
    background-size: contain;
    padding: 40px 25px;
    border-radius: 10px;
    position: relative;
    overflow: hidden;
}
[dir="rtl"] .home .second-section {
    background-position: left center;
}
.home .second-section img{
    position: absolute;
    top: 100%;
    left: 0%;
    transform: translate(-50%, -50%);
}
.home .second-section >div{
    width: 70%;
}
.home .second-section >div h4{
    font-size: 30px;
    font-weight: 700;
    line-height: 30px;
}
.home .second-section >div p{
    line-height: 30px;
    margin-top: 30px;
}
.home .second-section >div button{
    background-color: var(--white-color);
    border-radius: 5px;
    color: var(--primary-color);
    border: 0px;
    font-size: 16px;
    font-weight: 700;
    padding: 5px 20px;
}
.home .hero-home img{
    width: 100%;
    position: relative;
}

.home .hero-home h1{
    position: absolute;
    top: 40%;
    font-size: 50px;
    font-weight: 700;
    transform: translateY(-50%);
    color: #fff;
    padding: 10px 20px;
    max-width: 700px;
    line-height: 60px;
}
.home .hero-home{
    position: relative;
}

@media screen and (max-width: 1000px) {
    .home .hero-home h1{
        font-size: 20px;
        top: 30%;
        line-height: normal;
    }
}
@media screen and (max-width: 763px) {
    .home .second-section{
        background-image: none;
        text-align: center;
    }
    .home .second-section >div{
        width: 100%;
    }
}