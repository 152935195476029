.about{
    background-color: #F6F6F6;
    padding: 50px 0px;
}
.about h2{
    text-align: center;
    font-size: 40px;
    font-weight: 900;
    color: var(--primary-color);
}
.about .container > p{
    text-align: center;
    font-size: 16px;
    max-width: 900px;
    margin: auto;
}
.about h4{
    font-size: 33px;
    font-weight: 700;
    color: var(--primary-color);
    text-transform: uppercase;
    margin-bottom: 15px;
}
.about img{
    width: 90%;
    height: 50%;
    display: block;
    margin: auto;
}
.about ul li{
    margin-bottom: 10px;
    font-size: 16px;
}
.about a{
    font-size: 24px;
    color: var(--primary-color);
    text-decoration: none;
    font-weight: 700;
}