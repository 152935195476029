.achievement-card h3{
    width: 100%;
    padding: 10px 20px;
    background-color: var(--primary-color);
    border-radius: 15px 15px 0px 0px ;
    color: var(--white-color);
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 0;
}
.achievement-card{
    max-height: 500px;
    margin-bottom: 30px;
    border-radius: 15px;
    overflow: hidden;
}
.achievement-card > div{
    height: 100%;
    overflow: hidden;
    position: relative;
    border-radius: 15px;

}
.achievement-card img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.achievement-card > div p{
    position: absolute;
    color: #E4E4E4;
    top: 92%;
    width: 100%;
    padding: 10px 20px;
    z-index: 22;
    transform: translateY(-100%);
    font-size: 16px;
    letter-spacing: 1.3px;
    line-height: 30px;
}
.achievement-card > div p a{
    font-weight: 700;
    text-decoration: none;
    color: var(--primary-color);
}