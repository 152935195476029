.the-lab{
    margin-top: var(--margin);
}
.the-lab .hero-page{
    width: 100%;
}
.the-lab .hero-page img{
    width: 100%;
}
.the-lab .description{
    margin-top: calc(var(--margin) * 1.5);
}
.the-lab .description h3{
    font-size: 30px;
    font-weight: 700;
    color: var(--primary-color);
    margin-bottom: var(--margin);
}
.the-lab .description p{
    font-size: 17px;
    color: #5B5B5B;
}
.the-lab .remarque{
    background-color: #F6F6F6;
    text-align: center;
    border-radius: 10px;
    padding: 20px 10px;
}
.the-lab .remarque p{
    max-width: 800px;
    font-size: 15px;
    margin: auto;
}