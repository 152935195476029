:root{
  --primary-color:#5658FF;
  --white-color:#FFF;
  --margin:20px
}
a{
  text-decoration: none;
}
.app{
  overflow: hidden;
}
input , textarea{
  outline: 0cap;
}
body {
  direction: ltr;
  text-align: left;
}

body[dir="rtl"] {
  direction: rtl;
  text-align: right;
}
.row > div{
  margin-top: 20px;
}