.contact{
  padding: 30px 0px;
}
.contact .row > div{
 margin-top: 20px;
}
.contact h4 {
  color: var(--primary-color);
  font-size: 3.4rem;
  font-weight: 800;
}
.contact p {
  font-size: 16px;
}
.contact form div {
  width: 100%;
}
.contact form > div {
  margin-top: 10px;
}
.contact .name {
  display: flex;
  gap: 10px;
}
.contact label {
  display: block;
  margin-bottom: 9px;
  font-size: 12px;
  font-weight: 700;
}
.contact input,
.contact textarea {
  width: 100%;
  border: 1px solid rgba(128, 128, 128, 0.515);
  padding: 10px 20px;
  border-radius: 5px;
  font-size: 12px;
}
.contact textarea {
  height: 120px;
}
.contact button {
  background-color: var(--primary-color);
  color: var(--white-color);
  width: 100%;
  border: 0px;
  margin-top: 10px;
  border-radius: 5px;
  padding: 10px;
  font-weight: 800;
  font-size: 12px;
}
.contact .email{
  margin-top: 20px;
}
.contact .email,
.contact .phone {
  display: flex;
  gap: 10px;
  padding: 10px 5px;
}
.contact .email > div:first-of-type,
.contact .phone > div:first-of-type {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: #DFE1EB;
  color: #1E285C;
  display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;

}

.contact .email span , .contact .phone span{
  font-weight: 700;
}
.contact .email p , .contact .phone p{
  color: gray;
}
.contact .error_contact{
  color: red;
  font-size: 12px;
  font-weight: 700;
}