.our-company .second-section {
  background-color: var(--primary-color);
  color: var(--white-color);
  background-image: url(../../assets/img/image-7.png);
  background-repeat: no-repeat;
  background-position: right center;
  background-size: contain;
  padding: 40px 25px;
  border-radius: 10px;
  position: relative;
  overflow: hidden;
}

[dir="rtl"] .our-company .second-section {
  background-position: left center;
}
.our-company .second-section img:first-of-type {
  position: absolute;
  top: 100%;
  left: 0%;
  transform: translate(-50%, -50%);
}
.our-company .second-section img:last-of-type {
  position: absolute;
  top: 0%;
  left: 100%;
  transform: translate(-50%, -50%);
}
.our-company .second-section > div {
  width: 70%;
}
.our-company .second-section > div h4 {
  font-size: 30px;
  font-weight: 700;
  line-height: 30px;
}
.our-company .second-section > div p {
  line-height: 30px;
  margin-top: 30px;
}
@media screen and (max-width: 763px) {
  .our-company .second-section {
    background-image: none;
  }
  .our-company .second-section > div{
    width: 100%;
    text-align: center;
  }
}
