.our_history > div{
    background-color: var(--primary-color);
    padding: 20px 20px;
    color: var(--white-color);
    border-radius: 20px;
}
.our_history h2{
    text-align: center;
    font-size: 40px;
    font-weight: 900;
    padding: 10px 0px 30px 0px;
}
.container-img{
    width: 100%;
    height: 200px;
}
.our_history img{
   width: 100%;
    height: 200px;
    object-fit: cover;
    overflow: hidden;
    border-radius: 10px;
}
.our_history h4{
    text-align: end;
    position: relative;
}
.our_history h4::after{
    content: '';
    position: absolute;
    width: calc(100% - 72px);
    height: 2px;
    background: #fff;
    top: 50%;
    left: 0%;
}
.our_history h4::before{
        content: '';
    width: 10px;
    height: 10px;
    border-radius: 50%;
    position: absolute;
    background: #fff;
    top: calc(50% - 4px);
    left: calc(100% - 77px);
}
.our_history .custom-next1 , .our_history .custom-prev1{
    filter: grayscale(100%) brightness(200%);
}
.our_history .custom-next1 img , .our_history .custom-prev1 img{
    filter: grayscale(100%) brightness(200%);
    width: 80px;
    height: 80px;
}
.our_history h3{
    font-size: 25px;
    font-weight: 800;
}
.our_history p{
    font-size: 16px;
    font-weight: 300;
}