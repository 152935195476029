.hero_our-company{
    width: 95%;
    margin: auto;
    background-color: var(--primary-color);
    color: var(--white-color);
    font-size: 35px;
    text-align: center;
    font-weight: 900;
    text-transform: uppercase;
    height: 150px;
    line-height: 100px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    overflow: hidden;
}
.hero_our-company .first-img{
    position: absolute;
    top: 0;
    left: 0%;
    transform: translate(-50% , -50%);
}
.hero_our-company .second-img{
    position: absolute;
    top: 100%;
    left: 100%;
    transform: translate(-50% , -50%);
}