.footer{
    background-color: var(--primary-color);
    padding: 30px 0px;
    color: var(--white-color);
    margin-top: 30px;
}
.footer h5{
    font-size: 18px;
    font-weight: 600;
}
.footer li a{
    font-size: 16px;
    margin-top: 10px;
    display: block;
    color: var(--white-color);
    text-decoration: none;
}
.footer ul {
    list-style: none;
    margin-left: 0px;
    padding-left: 0px;
    padding-right: 0px;
    margin-top: 20px;
}

.footer p{
    margin-top: 20px;
}