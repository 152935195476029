.jobs{
    text-align: center;
}
.jobs .slide-content img{
    height: 100px;
}
.jobs .slide-content{
    padding: 20px 20px;
    border: 2px solid #D6D6FF;
    height: 100%;
    box-shadow: 4px 4px 32px 0px #00000014;
    border-radius: 23px;
}
.jobs .slide-content > div{
    max-width: 300px;
    margin: auto;

}
.jobs h4{
    font-weight: 800;
    font-size: 21px;
    margin: 15px 0;
}
.jobs h5{
    color: var(--primary-color);
    font-weight: 700;
}
.jobs p{
    font-size: 15px;
}
.jobs button{
    background-color: var(--primary-color);
    color: var(--white-color);
    width: 100%;
    padding: 10px ;
    border: 1px solid var(--primary-color);
    transition: .5s;
    border-radius: 5px;
    
}
.jobs button:hover{
    background-color: var(--white-color);
    color: var(--primary-color);
}

